import React, { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload, Modal, Form, Input, Slider } from "antd";
import QrList from "../QrList/QrList";
import QRCode from "qrcode.react";

const UploadAudio = () => {
  const [id, setId] = useState("");
  const [qrUrl, setQrUrl] = useState("");
  const [can, setCan] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [qrOPen, setQrOpen] = useState(false);
  const [img, setImg] = useState();
  const [base64Image, setBase64Image] = useState("");
  const [ossUrl, setOssUrl] = useState("");
  useEffect(() => {
    const fetchImage = async () => {
      const response = await fetch(img);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64Image(base64String);
      };
      reader.readAsDataURL(blob);
    };
    if (img && qrUrl) {
      fetchImage();
    }
  }, [img]);
  useEffect(() => {
    if (base64Image && qrUrl) {
      setCan(document.getElementById("qrcode").toDataURL("image/jpg"));
    }
  }, [base64Image]);
  // 音频上传属性
  const props = {
    name: "files",
    action: process.env.REACT_APP_BASE_URL + "/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status === "done") {
        const res = info.file.response;
        setId(res.data.id);
        message.success(`${info.file.name} 上传成功`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} 上传错误`);
      }
    },
  };

  // 图片上传属性
  const imgProps = {
    name: "files",
    action: process.env.REACT_APP_BASE_URL + "/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status === "done") {
        const res = info.file.response;
        setOssUrl(res.data.ossUrl);
        message.success(`${info.file.name} 上传成功`);
      }
    },
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = (values) => {
    console.log();
    fetch(process.env.REACT_APP_BASE_URL + "/saveQr", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        qrcode: can,
        remark: values.remark,
        image: img,
      }),
    }).then((res) => {
      res.json().then((data) => {
        if (data.code === 0) {
          message.success("保存成功");
          setIsModalOpen(false);
        } else {
          message.error(data.msg);
          setIsModalOpen(false);
        }
      });
    });
  };
  // 生成二维码url
  const generateQrUrl = () => {
    if (id) {
      setQrUrl(`${process.env.REACT_APP_VUE_URL}/?id=${id}`);
      setImg(ossUrl);
      setTimeout(() => {
        setCanvas();
      }, 1200);
    } else {
      message.error("请先上传音频文件");
    }
  };
  const setCanvas = () => {
    setCan(document.getElementById("qrcode").toDataURL("image/jpg"));
  };
  const downloadQr = () => {
    const link = document.createElement("a");
    link.href = can;
    link.download = "qrcode.jpg";
    link.click();
    message.success("下载成功");
  };
  return (
    <>
      <div>
        <Upload {...props} maxCount={1} accept=".mp3, .wav, .ogg , .m4a, .aac">
          <Button
            style={{
              marginTop: "50px",
            }}
            type="primary"
            icon={<UploadOutlined />}
          >
            点击上传音频文件
          </Button>
        </Upload>

        <div style={{ marginTop: "20px" }}>
          支持.mp3 .wav .ogg .m4a .aac格式文件
        </div>
        <Upload {...imgProps} maxCount={1} accept=".png, .jpg, .jpeg">
          <Button
            style={{
              marginTop: "50px",
            }}
            type="primary"
            icon={<UploadOutlined />}
          >
            点击上传图片（可选，用于二维码中间图片）
          </Button>
        </Upload>
        <div
          style={{
            marginTop: "50px",
            background: "#1677ff",
            padding: "10px",
            color: "#fff",
          }}
        >
          预览二维码扫后的页面
        </div>
        <iframe
          id="myIframe"
          src={`${process.env.REACT_APP_VUE_URL}?id=${id}`}
          width="100%"
          height="200px"
          style={{
            marginTop: "50px",
            border: "none",
          }}
        ></iframe>
        <div
          style={{
            marginTop: "50px",
            background: "#1677ff",
            padding: "10px",
            color: "#fff",
          }}
        >
          二维码生成结果
        </div>
        <div>
          {" "}
          <Button
            style={{ marginTop: "10px" }}
            type="primary"
            onClick={generateQrUrl}
          >
            点击生成二维码
          </Button>
          <Button
            onClick={() => {
              setQrOpen(true);
            }}
            type="primary"
            style={{ marginTop: "20px", marginLeft: "20px" }}
          >
            查看历史生成记录
          </Button>
        </div>

        {qrUrl && (
          <div id="myqrcode" style={{ display: "inline-block" }}>
            <QRCode
              id="qrcode"
              value={qrUrl}
              size={240}
              color="#000" //二维码颜色
              fgColor="#000" //背景颜色
              level="H" //误差值
              includeMargin // 二维码边缘是否需要留白 默认值false
              style={{ cursor: "pointer" }}
              crossOrigin="anonymous"
              imageSettings={
                base64Image && {
                  crossOrigin: "anonymous",
                  src: base64Image, //图片地址
                  width: 50, //图片宽度
                  height: 50, //图片高度
                  excavate: true, //图片是否镂空，默认false
                  allowTaint: true,
                  useCORS: true, //是否允许CORS跨域，默认false
                }
              }
            ></QRCode>
          </div>
        )}
        {qrUrl && (
          <div style={{ marginBottom: "20px" }}>
            <Button type="primary" onClick={downloadQr}>
              下载
            </Button>

            <Button
              onClick={showModal}
              type="primary"
              style={{ marginLeft: "20px" }}
            >
              保存记录
            </Button>
          </div>
        )}
        <Modal
          title="保存到记录"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            name="basic"
            style={{
              maxWidth: 600,
              padding: "20px 0px",
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label="备注"
              name="remark"
              rules={[
                {
                  required: true,
                  message: "请填写备注",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
            <Button onClick={handleCancel} style={{ marginLeft: "20px" }}>
              取消
            </Button>
          </Form>
        </Modal>
        <QrList openQrList={qrOPen} cancel={() => setQrOpen(false)} />
      </div>
    </>
  );
};
export default UploadAudio;
