import './App.css';
import UploadAudio from './Components/UploadAudio/UploadAudio';
function App() {
    return (
        <div className="App">
            <UploadAudio />

        </div>
    );
}

export default App;
