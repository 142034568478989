import React, { useState, useEffect } from "react";
import { Table, Modal, Pagination } from "antd";
const QrList = (props) => {
  const { openQrList, cancel } = props;
  const [qrList, setQrList] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (openQrList) {
      fetch(
        process.env.REACT_APP_BASE_URL +
          `/getQrList?pageNum=${pageNum}&pageSize=${pageSize}`,
        { method: "GET", headers: { "Content-Type": "application/json" } }
      )
        .then((res) => res.json())
        .then((res) => {
          setQrList(res.data.qrList);
          setTotal(res.data.total);
        });
    }
  }, [openQrList, pageNum, pageSize]);
  const columns = [
    { title: "备注", dataIndex: "remark", key: "remark" },
    {
      title: "图片",
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => (
        <img
          src={image}
          alt=""
          key={image}
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    { title: "创建时间", dataIndex: "createTime", key: "createTime" },
    {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
      render: (_, { qrcode }) => (
        <a href={qrcode} download="二维码" key={qrcode}>
          下载二维码
        </a>
      ),
    },
  ];
  const onChange = (pageNum, pageSize) => {
    setPageNum(pageNum);
    setPageSize(pageSize);
  };
  return (
    <div>
      <Modal
        title="二维码列表"
        open={openQrList}
        onCancel={cancel}
        footer={null}
        width={"80%"}
      >
        <Table dataSource={qrList} columns={columns} pagination={false} />
        <Pagination
          total={total}
          showSizeChanger={true}
          onChange={onChange}
          pageSizeOptions={["5", "10", "20", "30", "50", "100"]}
        />
      </Modal>
    </div>
  );
};

export default QrList;
